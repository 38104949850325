.wrapper {
  min-height: calc(100vh - 70px);
}
.app {
  background-color: #d4d4d496;
}
p { text-align: justify }
footer {
  text-align: center;
  background: #f0f2f5;
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.maxWidthContainer {
  max-width: 1200px;
  margin: 0 auto;
}

.card-detail {
  width: '50%';
  textAlign: 'center';
}
.description {
  list-style: none;
  text-align: left;
}
.status {
  background: #f1f1f1;
  border-radius: 2px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  margin: 10px 0;
}
.status:after {
  border-radius: 10px;
  border: 1px solid white;
  color: white;
  position: absolute;
  right: -70px;
  top: -8px;
  width: 80px;
  padding: 0 10px;
}
.status.sold:after {
  background: #ff7373;
  content: 'Vendido';
}
.status.onsale:after {
  background: #06aee6;
  content: 'En venta';
}
.ant-card-cover { cursor: pointer }
#circle-shape-example {
  font-family: Open Sans, sans-serif;
  margin: 2rem;
}
#circle-shape-example p {
  line-height: 1.8;
  font-size: 1.2em;
}
#circle-shape-example .curve {
  width: 33%; height: auto;
  width: 200px;
  float: left;
  margin-right: 2.5rem;
  border-radius: 50%;
  -webkit-shape-outside:circle();
  shape-outside:circle();
}
